import React from "react";
import CartCards from "./CartComponent/CartCards";
import DataService from "../../service/DataService";
import { useRestaurant } from "../../hooks/RestaurantContext";



const OrderConfirmationPage = () => {

    const url = new URL(window.location.href);
    const orderId = url.searchParams.get('id');

    const [productData, setProductData] = React.useState({});
    const [cart, setCart] = React.useState({})

    const { restaurantId } = useRestaurant();

    const setMenu = (e: any) => {
        setProductData(e.data.menu);
    }

    React.useEffect(() => {
        let cartData = localStorage.getItem(`order_${orderId}`);
        console.log("cartData: ", cartData)
        if (cartData) {
            setCart(JSON.parse(cartData));
        }
        console.log(JSON.stringify(cart))

    }, [orderId])

    React.useEffect(() => {
        DataService.GetData("restaurants", restaurantId, undefined, setMenu).then((result) => {
            setProductData(result.menu);
        });

    }, [])

    if (orderId && cart) {
        return (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "20px"}}>
                
                <h2>Your order has been confirmed! Your order number is {orderId}</h2>

                <div style={{width: "80%"}}>
                    <CartCards productData={productData} cart={cart} currencySymbol={"£"}/>               
                </div>

            </div>
        )
    }
    else {
        return (
            <div>
                <h2>Error: Page not found</h2>
            </div>
        )
    }
}

export default OrderConfirmationPage;