import React from "react";


const LogoPlatform = () => {

    return (
        <>
            <span className="text-2xl font-bold text-black" style={{fontWeight: "bold", color: "white", fontSize: "x-large"}}>Dine<span className="text-blue-600"  style={{fontWeight: "bold", color: "royalblue", fontSize: "x-large"}}>Platform</span></span>
        </>
    )
}

export default LogoPlatform;