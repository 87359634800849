// ThemeContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface Theme {
    backgroundColor: string;
    textColor: string;
    cardColor: string;
    topbarBackgroundColor: string,
    topbarIconColor: string,
    topbarBorderBottomColor: string,
    sidebarBackgroundColor: string,
    sidebarIconColor: string,
    sidebarIconColorSelected: string,
    sidebarTextColor: string,
    sidebarBorderColor: string,
    borderColor: string,
    categoryIndicatorColor: string,
    cartBackgroundColor: string,
    cartCardColor: string,
    cartTextColor: string,
    cartButtonColor: string,
    cartButtonTextColor: string,
    cartSummaryBorder: string,
    payButtonColor: string,
    payButtonTextColor: string,
    loaderColor: string,
    menuButtons: boolean,
}

interface ThemeContextType {
    theme: Theme;
    updateTheme: (newTheme: Partial<Theme>) => void;
}

interface ThemeProviderProps {
    children: ReactNode;
    mode?: 'light' | 'dark'; // Define mode as either 'light' or 'dark'
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = (): ThemeContextType => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context; // Return the context, including theme and changeTheme
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, mode }) => {

    const lightTheme = {
        backgroundColor: '#f7f3e9', // Light beige for a warm main background
        textColor: '#2b2b2b', // Dark charcoal for readability, softer than black
    
        // Topbar styling
        topbarBackgroundColor: '#121b26', // Warm beige for the topbar background
        topbarIconColor: '#3a3a3a', // Dark charcoal icons for better contrast
        topbarBorderBottomColor: '', // Muted warm gray for a subtle border
    
        // Sidebar styling
        sidebarBackgroundColor: '#efe8dd', // Matching warm beige for the sidebar
        sidebarIconColor: '#4a4a4a', // Charcoal gray for icons
        sidebarIconColorSelected: '#3b5998', // Muted royal blue for selection, adds a slight accent
        sidebarTextColor: '#3a3a3a', // Charcoal gray for readability
        sidebarBorderColor: '#d0c8ba', // Soft warm gray for a subtle border
    
        // Card and border colors
        cardColor: '#f7f3e9', // Soft, warm light gray for cards
        borderColor: '#c9c1b3', // Light warm gray for subtle borders
    
        // Additional elements
        categoryIndicatorColor: '#3b5998', // Muted blue for consistency
        cartBackgroundColor: '#efe8dd', // Light warm gray to complement the theme
        cartCardColor: '#f7f3e9', // White for clarity within the cart
        cartTextColor: '#2e2e2e', // Dark gray for readability
        cartButtonColor: '#3b5998', // Primary accent color for call-to-action
        cartButtonTextColor: '#ffffff', // White for contrast
    
        // Button styling
        cartSummaryBorder: '#d0c8ba', // Soft border to define summary
        payButtonColor: '#121b26', // Darker color to signify importance
        payButtonTextColor: '#ffffff', // White text for high visibility
    
        // Loader
        loaderColor: '#3b5998', // Consistent accent color for loading spinner
        
        menuButtons: true,

    };

    const darkTheme = {
        backgroundColor: '#101014',
        textColor: 'white',
        cardColor: '#1b1b21',
        topbarBackgroundColor: '#101014',
        topbarIconColor: 'white',
        topbarBorderBottomColor: '#101014',
        sidebarBackgroundColor: '#101014',
        sidebarIconColor: 'white',
        sidebarIconColorSelected: 'royalblue',
        sidebarTextColor: 'white',
        sidebarBorderColor: '#101014',
        borderColor: '#3b3b3b',
        categoryIndicatorColor: 'royalblue',
        cartBackgroundColor: '#1c1d24',
        cartCardColor: '#323441',
        cartTextColor: 'white',
        cartButtonColor: 'lightgrey',
        cartButtonTextColor: 'black',
        cartSummaryBorder: '#323441',
        payButtonColor: '#323441',
        payButtonTextColor: 'white',
        loaderColor: 'royalblue',
        menuButtons: false
    }


    const [theme, setTheme] = useState<Theme>(() => {
        // const savedTheme = localStorage.getItem('theme');
        // return savedTheme ? JSON.parse(savedTheme) : {
        //     backgroundColor: 'red',
        //     textColor: '#000000',
        //     cardColor: '#f0f0f0',
        // };
        if (mode === "dark"){
            return darkTheme;
        }
        return lightTheme;
    });

    useEffect(() => {
        localStorage.setItem('theme', JSON.stringify(theme));
    }, [theme]);

    const updateTheme = (newTheme: Partial<Theme>) => {
        setTheme((prevTheme) => ({
            ...prevTheme,
            ...newTheme,
        }));
    };

    return (
        <ThemeContext.Provider value={{ theme, updateTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};
