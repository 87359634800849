import React from "react";
import { getConfig } from "../config/config";


const Logo = () => {

    const config = getConfig();

    return (
        <>
            <img src={config.logoUrl} height="80%" />
        </>
    )
}

export default Logo;