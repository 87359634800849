import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../hooks/ThemeContext";
import Select from 'react-select';


type Props = {
    onSave: any,
    defaultValues?: any,
    editMode?: boolean
    menu: any
}

const ModifyProduct = (props: Props) => {

    const { theme } = useTheme();
    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    React.useEffect(() => {
        if (props.menu.filter((x: any) => x.productId === props.defaultValues.productId)[0]){
            let itemData = props.menu.filter((x: any) => x.productId === props.defaultValues.productId)[0]
            let selectedIds = itemData.additionalOptions;
            if (selectedIds) {
                setSelectedOptions(props.menu.map((menu: any) => ({
                    value: menu.productId,
                    label: menu.productName + ` (${menu.category})`,
                })).filter((x:any) => selectedIds.includes(x.value)))
            }
        }
    }, [props.menu])

    // Handle change in selection
    const handleChange = (selected: any) => {
        setSelectedOptions(selected || []);
    };

    const handleSave = (event: any) => {
        event.preventDefault(); // Prevent the default form submission
    
        // Access form elements directly using ref
        const form = formRef.current;

        let output_data: any = {};

        if (props.defaultValues && props.defaultValues.productId) {
            output_data["productId"] = props.defaultValues.productId;
        }

        if (props.defaultValues && props.defaultValues.category) {
            output_data["category"] = props.defaultValues.category;
        }
    
        if (form) {
            // Get the values from the form fields
            for (let i = 0; i < form.elements.length; i++) {
                const field = form.elements[i] as HTMLInputElement; // Casting to a specific type

                // Check if the element is an input or any other field
                if (field.name) {
                    if (field.type === 'checkbox') {
                        output_data[field.name] = field.checked ? true : false;
                    } 
                    else if (field.type === 'number') {
                        output_data[field.name] = parseFloat(field.value)
                    }
                    else {
                        output_data[field.name] = field.value 
                    }                
                }
            }
            output_data["additionalOptions"] = selectedOptions.map((x: any) => x.value);
            props.onSave(output_data)
        
            // You can also reset the form if needed
            // form.reset(); // Optional: Reset the form after submission
        }
    };

    const formRef = useRef<HTMLFormElement | null>(null);


    return (
        <>
            <span style={{fontWeight: "bold"}}>{props.editMode ? "Edit Product" : "Add Product"}</span>
            <form ref={formRef} onSubmit={handleSave}>
                <div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>
                    

                    <label style={{marginTop: "0px"}}>Product Name</label>
                    <input name="productName" type="text" style={{marginTop: "5px", padding: "10px", boxSizing: "border-box"}} defaultValue={props.defaultValues && props.defaultValues.productName} required/>

                    <label style={{marginTop: "20px"}}>Product Description</label>
                    <textarea name="productDescription" style={{fontFamily: "inherit", padding: "10px", marginTop: "5px", height: "100px"}} defaultValue={props.defaultValues && props.defaultValues.productDescription}/>
                    
                    <label style={{marginTop: "20px"}}>Product Price</label>
                    <input name="productPrice" type="number" step="0.01" style={{marginTop: "5px", padding: "10px", boxSizing: "border-box"}} defaultValue={props.defaultValues && props.defaultValues.productPrice} required/>

                    <label style={{marginTop: "20px"}}>Product Thumbnail URL</label>
                    <input name="productThumbnailUrl" type="text" style={{marginTop: "5px", padding: "10px", boxSizing: "border-box"}} defaultValue={props.defaultValues && props.defaultValues.productThumbnailUrl}/>

                    <label style={{marginTop: "20px", marginBottom: "5px"}}>Additional Options</label>

                    <Select
                        isMulti
                        options={props.menu.map((menu: any) => ({
                            value: menu.productId,
                            label: menu.productName + ` (${menu.category})`,
                        }))}
                        value={selectedOptions}
                        onChange={handleChange}
                        placeholder="Select options..."
                        styles={{option: (provided: any, state: any) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? 'blue' : state.isFocused ? 'lightblue' : 'white',
                            color: state.isSelected ? 'white' : 'black',
                          }),}}
                    />

                    <button type="submit" style={{backgroundColor: "royalblue", color: theme.cartButtonTextColor, border: "none", width: "100%", marginTop: "30px", marginRight: "20px", padding: "10px", boxSizing: "border-box", cursor: "pointer"}}>Submit</button>


                </div>
                
            </form>
        </>
    )
}

export default ModifyProduct;