import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../hooks/ThemeContext";

type Props = {
    onSave: any,
    defaultValues?: any,
    editMode?: boolean
}


const ModifyMenu = (props: Props) => {

    const { theme } = useTheme();

    // Track which accordion (day) is open
    const [openDay, setOpenDay] = useState(null);

    const daysOfWeek = [
        "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"
    ];

    // Handle the toggle event to ensure only one accordion is open at a time
    const handleToggle = (day: any, event: any) => {
        // If the details are opened, set the openDay to that day, otherwise set it to null
        if (event.target.open) {
            if (day !== openDay) {
                setOpenDay(day);
            }
        }
    };

    const handleSave = (event: any) => {
        event.preventDefault(); // Prevent the default form submission
    
        // Access form elements directly using ref
        const form = formRef.current;

        let output_data: any = {};
        output_data["timings"] = {}
    
        if (form) {
            // Get the values from the form fields
            for (let i = 0; i < form.elements.length; i++) {
                const field = form.elements[i] as HTMLInputElement; // Casting to a specific type

                // Check if the element is an input or any other field
                if (field.name) {
                    if (field.type === 'checkbox') {
                        output_data[field.name] = field.checked ? true : false;
                    } else {
                        if (field.name.includes("-")) {
                            let nameObj = field.name.split("-");
                            let day = nameObj[0];
                            let attr = nameObj[1];
                            if (output_data["timings"][day] !== undefined) {
                                output_data["timings"][day][attr] = field.value;
                            }
                            else {
                                output_data["timings"][day] = {}
                                output_data["timings"][day][attr] = field.value;
                            }

                        }
                        else {
                            output_data[field.name] = field.value
                        }
                    }                
                }
            }
            
            props.onSave(output_data)
        
            // You can also reset the form if needed
            // form.reset(); // Optional: Reset the form after submission
        }
    };

    const formRef = useRef<HTMLFormElement | null>(null);


    return (
        <>
            <span style={{fontWeight: "bold"}}>{props.editMode ? "Edit Menu" : "Add Menu"}</span>
            <form ref={formRef} onSubmit={handleSave}>
                <div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <label>Enabled</label>
                        <input name="enabled" style={{marginLeft: "10px"}} type="checkbox" defaultChecked={props.defaultValues.enabled}/>
                    </div>
                    

                    <label style={{marginTop: "20px"}}>Menu Name</label>
                    <input name="menuName" type="text" style={{marginTop: "5px", marginRight: "20px", padding: "10px", boxSizing: "border-box"}} defaultValue={props.defaultValues.menuName || ""} disabled={props.editMode} required/>

                    {/* Accordion for each day of the week using <details> */}
                    <div style={{ marginTop: '20px'}}>
                        {daysOfWeek.map((day) => (

                            <summary key={day} style={{display: "flex", flexDirection: "row", fontSize: '18px', cursor: 'pointer' }}>
                                <span style={{flexGrow: 1}}>{day.charAt(0).toUpperCase() + day.slice(1)}: </span>
                                {/* ({startTimeString[day as keyof TimeString]} - {endTimeString[day as keyof TimeString]}) */}
                                <div style={{marginRight: "20px"}}>
                                <input
                                    style={{ marginLeft: '10px' }}
                                    name={`${day}-start`}
                                    type="time"
                                    defaultValue={props.defaultValues.timings[day].start || "00:00"}
                                    // onChange={(e)=>{setStartTimeString(prev => ({...prev, [day]: e.target.value}))}}
                                    required
                                /> &nbsp;
                                 to 
                                <input
                                style={{ marginLeft: '10px' }}
                                name={`${day}-end`}
                                type="time"
                                defaultValue={props.defaultValues.timings[day].end || "00:00"}
                                // onChange={(e)=>{setEndTimeString(prev => ({...prev, [day]: e.target.value}))}}
                                required
                                />
                                </div>
                            </summary>


                        ))}
                    </div>

                    <button type="submit" style={{backgroundColor: "royalblue", color: theme.cartButtonTextColor, border: "none", width: "100%", marginTop: "20px", marginRight: "20px", padding: "10px", boxSizing: "border-box", cursor: "pointer"}}>Submit</button>
                
                </div>
                
            </form>
        </>
    )
}

export default ModifyMenu;