import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../hooks/ThemeContext";
import ManagementCard from "./ManagementCard";
import { useAuth } from "../../hooks/useAuth";
import DataService from "../../service/DataService";
import ReactModal from "react-modal";
import ModifyMenu from "./ModifyMenu";
import { HiPlus } from "react-icons/hi";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ModifyCategory from "./ModifyCategory";
import ModifyProduct from "./ModifyProduct";
import "./MenuManagerStyles.css"


const MenuManagerPage = () => {

    const theme = useTheme();
    const auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [restaurantList, setRestaurantList] = useState<any>([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState("");
    const [menuList, setMenuList] = useState<any>([])
    const [selectedMenu, setSelectedMenu] = useState("")
    const [restaurantData, setRestaurantData] = useState<any>({});
    const [updatedRestaurantData, setUpdatedRestaurantData] = useState<any>({});
    const [categoryList, setCategoryList] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState("")
    const [selectedMenuItem, setSelectedMenuItem] = useState("");
    const [formView, setFormView] = useState("");
    const [formViewId, setFormViewId] = useState("");
    const [formViewName, setFormViewName] = useState("");

    function generateShortUUID() {
        return 'xxxxxx'.replace(/[x]/g, function() {
            const r = Math.random() * 16 | 0;
            return r.toString(16); // generates a hex character
        }).toUpperCase(); // Optional: Convert to uppercase
    }

    // useEffect(() => {
    //     console.log(updatedRestaurantData)
    // }, [updatedRestaurantData])

    const handleUpdate = () => {
        try {
            DataService.addData("restaurants", updatedRestaurantData, undefined, selectedRestaurant).then((response) => {
                window.location.reload();
            });
        }
        catch {
            console.log("error updating menu")
        }
    }

    const handleAddMenu = (data: any) => {
        data["categories"] = [];
        setUpdatedRestaurantData((prev: any) => ({...prev, menuSettings: [...prev.menuSettings, data]}))
        setSelectedMenu(data["menuName"])
        onModalClose();
    }

    const handleEditMenu = (data: any) => {
        setUpdatedRestaurantData((prev: any) => ({
            ...prev,
            menuSettings: prev.menuSettings.map((item: any) =>
              item.menuName === data.menuName ? { ...item, ...data } : item
            ),
        }));
        onModalClose();
    }

    const handleAddCategory = (categoryData: any) => {
        categoryData["id"] = categoryData.name;
        setUpdatedRestaurantData((prev: any) => {
            const updatedMenuSettings = prev.menuSettings.map((menu: any) => {
                if (menu.menuName === selectedMenu) {
                    // Add the new category to the categories array of the matching menu
                    return {
                        ...menu,
                        categories: [...menu.categories, categoryData],
                    };
                }
                return menu; // Keep other menus unchanged
            });
    
            return { ...prev, menuSettings: updatedMenuSettings };
        });
        setSelectedCategory(categoryData["id"])
        onModalClose();
    };

    const handleEditCategory = (categoryData: any) => {
        console.log(categoryData)
        setUpdatedRestaurantData((prev: any) => ({
            ...prev,
            menuSettings: prev.menuSettings.map((menu: any) => 
                menu.menuName === selectedMenu 
                    ? { 
                        ...menu, 
                        categories: menu.categories.map((category: any) =>
                            category.id === categoryData.id
                                ? { ...category, ...categoryData }
                                : category
                        )
                    } 
                    : menu
            ),
        }));
        onModalClose();
    };

    const handleAddProduct = (data: any) => {
        data["productId"] = generateShortUUID();
        data["order"] = updatedRestaurantData.menu.length;
        data["menus"] = [selectedMenu]
        console.log(data)
        setUpdatedRestaurantData((prev: any) => ({...prev, menu: [...prev.menu, data]}))
        setSelectedMenuItem(data["productId"])
        onModalClose();
    }

    const handleEditProduct = (data: any) => {
        setUpdatedRestaurantData((prev: any) => ({
            ...prev,
            menu: prev.menu.map((item: any) =>
              item.productId === data.productId ? { ...item, ...data } : item
            ),
        }));
        onModalClose();
    }

    const onModalClose = () => {
        setFormView("")
    }

    const moveCard = (fromIndex: number, toIndex: number) => {
        setUpdatedRestaurantData((prev: any) => {
          const updatedMenuSettings = [...prev.menuSettings];
          const [movedItem] = updatedMenuSettings.splice(fromIndex, 1); // Remove the dragged item
          updatedMenuSettings.splice(toIndex, 0, movedItem); // Insert it at the new position
    
          // Update the order attribute based on the new array index
          updatedMenuSettings.forEach((item, index) => {
            item.order = index + 1;
          });
    
          return { ...prev, menuSettings: updatedMenuSettings };
        });
    };

    const moveCategoryCard = (fromIndex: number, toIndex: number) => {
        setUpdatedRestaurantData((prev: any) => {
          // Find the selected menu
          const updatedMenuSettings = prev.menuSettings.map((menu: any) => {
            if (menu.menuName === selectedMenu) {
              // Copy the categories array
              const updatedCategories = [...menu.categories];
              const [movedItem] = updatedCategories.splice(fromIndex, 1); // Remove the dragged item
              updatedCategories.splice(toIndex, 0, movedItem); // Insert it at the new position
      
              // Update the order attribute based on the new array index
              updatedCategories.forEach((item, index) => {
                item.order = index + 1;
              });
      
              // Return the updated menu with modified categories
              return { ...menu, categories: updatedCategories };
            }
            return menu; // Return other menus unchanged
          });
      
          // Return the updated state
          return { ...prev, menuSettings: updatedMenuSettings };
        });
    };

    const moveProductCard = (fromIndex: number, toIndex: number) => {
        setUpdatedRestaurantData((prev: any) => {
            const updatedMenu = [...prev.menu];
            const [movedItem] = updatedMenu.splice(fromIndex, 1); // Remove the dragged item
            updatedMenu.splice(toIndex, 0, movedItem); // Insert it at the new position
      
            // Update the order attribute based on the new array index
            updatedMenu.forEach((item, index) => {
              item.order = index + 1;
            });
      
            return { ...prev, menu: updatedMenu };
          });

    };

    const modalCustomStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: theme.theme.cartBackgroundColor,
          color: theme.theme.cartTextColor,
          padding: '20px',
          border: 'solid 1px black',
          borderRadius: '20px',
          width: '70svw',
          minWidth: '350px',
          maxWidth: '400px',
          maxHeight: '100svh',
          zIndex: '100000000',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '100000000',
    
        }
    };

    const closeButtonStyles = {
        position: 'absolute' as 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        fontSize: '24px',
    };
    
    const crossIconStyles = {
        fontSize: '24px',
        color: '#333',
    };

    React.useEffect(() => {
        if (auth?.roles.includes("ADMIN")) {
            setLoading(true);
            DataService.GetData("admin_data", "data").then((response) => {
                if (response.restaurantList.length > 0){
                    setRestaurantList(response.restaurantList)
                    setSelectedRestaurant(response.restaurantList[0].id)
                }
                else {
                    console.log("No restaurants found")
                }
                setLoading(false);
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [auth?.roles])

    React.useEffect(() => {
        if (auth?.roles.includes("ADMIN") && selectedRestaurant !== "") {
            setLoading(true);
            DataService.GetData("restaurants", selectedRestaurant, undefined, undefined, true).then((response) => {
                if (response.menuSettings.length > 0){
                    setRestaurantData(response);
                    setUpdatedRestaurantData(response);
                    setMenuList(response.menuSettings)
                    setSelectedMenu(response.menuSettings.filter((m: any) => m.enabled === true)[0].menuName)
                    // setRestaurantList(response.restaurantList)
                    // setSelectedRestaurant(response.restaurantList[0].id)
                }
                else {
                    console.log("No menus found")
                }
                setLoading(false);
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [selectedRestaurant])

    React.useEffect(() => {
        if (auth?.roles.includes("ADMIN") && selectedMenu !== "" && updatedRestaurantData) {
            let uniqueCategories = new Set();
            for (let menuItem of updatedRestaurantData.menu.filter((m: any) => m.menus.includes(selectedMenu))) {
                uniqueCategories.add(menuItem.category);
            }
            // console.log(uniqueCategories)
            let cArray: any[] = Array.from(uniqueCategories);
            setCategoryList(cArray)
            setSelectedCategory(cArray[0])

            let categories = updatedRestaurantData.menuSettings.filter((x:any) => x.menuName === selectedMenu)[0].categories;

            if (categories.length > 0) {
                setCategoryList(categories)
                setSelectedCategory(categories[0].id)
            }

        }
    }, [selectedMenu])

    return (
        <DndProvider backend={HTML5Backend}>
        <div style={{display: "flex", flexDirection: "column", overflowX: "scroll", width: "100%", height: "100%", backgroundColor: theme.theme.backgroundColor}}>
            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, width: "100%", height: "100%", overflowX: "scroll"}}>
            <div className="lane" style={{display: "flex", flexDirection: "column", height: "100%", minWidth: "200px", width: "15%", border: "1px solid " + theme.theme.borderColor, color: theme.theme.sidebarIconColor}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme.theme.borderColor, height: "40px", fontWeight: "bold", flexShrink: 0}}>
                    <span style={{flexGrow: 1, fontSize: "small"}}>Restaurants &nbsp;</span>
                    {/* <span onClick={()=>setFormView("addRestaurant")} style={{display: "flex", alignItems: "center", backgroundColor: "royalblue", fontSize: "small", color: "#ccc", padding: "3px", borderRadius: "10px", cursor: "pointer", marginRight: "12px"}}><HiPlus /></span> */}
                </div>
                <div style={{flexGrow: 1, overflowY: "scroll"}}>

                    {
                        restaurantList.map((restaurant: any, index: any) => {
                            return (
                                <ManagementCard key={index} index={index} type={"shop"} id={restaurant.id} text={restaurant.name} moveCard={moveCard} onClick={setSelectedRestaurant} isSelected={selectedRestaurant === restaurant.id}/>
                            )
                        })
                    }

                </div>
            </div>

            <div className="lane" style={{display: "flex", flexDirection: "column", marginLeft: "1px", height: "100%", minWidth: "200px", width: "15%", border: "1px solid " + theme.theme.borderColor, color: theme.theme.sidebarIconColor}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme.theme.borderColor, height: "40px", fontWeight: "bold", flexShrink: 0}}>
                    <span style={{flexGrow: 1, fontSize: "small"}}>Menus &nbsp;</span>
                    <span onClick={()=>setFormView("addMenu")} style={{display: "flex", alignItems: "center", backgroundColor: "royalblue", fontSize: "small", color: "#ccc", padding: "3px", borderRadius: "10px", cursor: "pointer", marginRight: "12px"}}><HiPlus /></span>
                </div>
                <div style={{flexGrow: 1, overflowY: "scroll"}}>

                    {
                        updatedRestaurantData.menuSettings && updatedRestaurantData.menuSettings.map((menu: any, index: any) => {
                            return (
                                <ManagementCard key={index} index={index} type={"menu"} id={menu.menuName} moveCard={moveCard} text={menu.menuName} onClick={setSelectedMenu} onEditClick={(id: string)=>{setFormView("editMenu"); setFormViewId(id)}} isSelected={selectedMenu === menu.menuName}/>
                            )
                        })
                    }

                </div>
            </div>

            <div className="lane" style={{display: "flex", flexDirection: "column", marginLeft: "1px", height: "100%", minWidth: "200px", width: "20%", border: "1px solid " + theme.theme.borderColor, color: theme.theme.sidebarIconColor}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme.theme.borderColor, height: "40px", fontWeight: "bold", flexShrink: 0}}>
                    <span style={{flexGrow: 1, fontSize: "small"}}>Categories &nbsp;</span>
                    <span onClick={()=>setFormView("addCategory")} style={{display: "flex", alignItems: "center", backgroundColor: "royalblue", fontSize: "small", color: "#ccc", padding: "3px", borderRadius: "10px", cursor: "pointer", marginRight: "12px"}}><HiPlus /></span>
                </div>
                <div style={{flexGrow: 1, overflowY: "scroll"}}>

                    {
                       updatedRestaurantData.menuSettings && updatedRestaurantData.menuSettings.filter((x: any) => x.menuName === selectedMenu)[0] && updatedRestaurantData.menuSettings.filter((x: any) => x.menuName === selectedMenu)[0].categories.map((category: any, index: any) => {
                            return (
                                <ManagementCard key={index} index={index} type={"category"} id={category.id} moveCard={moveCategoryCard} text={category.name} onClick={setSelectedCategory} onEditClick={(id: string)=>{setFormView("editCategory"); setFormViewId(id); setFormViewName(category.name)}} isSelected={selectedCategory === category.id}/>
                            )
                        })
                    }

                </div>
            </div>

            <div className="lane" style={{display: "flex", flexDirection: "column", marginLeft: "1px", height: "100%", minWidth: "200px", width: "20%", border: "1px solid " + theme.theme.borderColor, color: theme.theme.sidebarIconColor}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme.theme.borderColor, height: "40px", fontWeight: "bold", flexShrink: 0}}>
                    <span style={{flexGrow: 1, fontSize: "small"}}>Products &nbsp;</span>
                    <span onClick={()=>setFormView("addProduct")} style={{display: "flex", alignItems: "center", backgroundColor: "royalblue", fontSize: "small", color: "#ccc", padding: "3px", borderRadius: "10px", cursor: "pointer", marginRight: "12px"}}><HiPlus /></span>
                </div>
                <div style={{flexGrow: 1, overflowY: "scroll"}}>

                    {updatedRestaurantData.menu && 
                       updatedRestaurantData.menu.map((menuItem: any, index: any) => {
                            if (menuItem.category === selectedCategory){
                                return (
                                    <ManagementCard key={index} index={index} type={"product"} id={menuItem.productId} moveCard={moveProductCard} text={menuItem.productName} onClick={setSelectedMenuItem} onEditClick={(id: string)=>{setFormView("editProduct"); setFormViewId(id);}} isSelected={menuItem.productId === selectedMenuItem}/>
                                )
                            }
                            else {
                                return (
                                    <ManagementCard donotdisplay key={index} index={index} type={"product"} id={menuItem.productId} moveCard={moveProductCard} text={menuItem.productName} onClick={setSelectedMenuItem} isSelected={menuItem.productId === selectedMenuItem}/>
                                )  
                            }
                        })
                    }

                </div>
            </div>

            {/* <div style={{display: "flex", flexDirection: "column", marginLeft: "1px", height: "100%", minWidth: "200px", width: "30%", border: "1px solid " + theme.theme.borderColor, color: theme.theme.sidebarIconColor}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme.theme.borderColor, height: "40px", fontWeight: "bold", flexShrink: 0}}>
                    <span style={{fontSize: "small"}}>Products</span>
                </div>

                <div style={{flexGrow: 1, overflowY: "scroll"}}>

                </div>
            </div> */}

            </div>

            { JSON.stringify(restaurantData) !== JSON.stringify(updatedRestaurantData) &&
                <div style={{height: "50px", display: "flex", flexDirection: "row", flexGrow: 1, backgroundColor: theme.theme.borderColor, textAlign: "left", padding: "10px", boxSizing: "border-box", width: "100%", overflow: "hidden"}}>
                    <button onClick={()=>setUpdatedRestaurantData(restaurantData)} style={{display: "flex", alignItems: "center", height:"30px", padding: "10px", boxSizing: "border-box", border: "2px solid royalblue", backgroundColor: theme.theme.borderColor, color: "white", borderRadius: "5px", cursor: "pointer"}}>Discard</button>
                    <button onClick={handleUpdate} style={{display: "flex", alignItems: "center", height: "30px", padding: "10px", boxSizing: "border-box", border: "2px solid royalblue", backgroundColor: "royalblue", color: "white", borderRadius: "5px", cursor: "pointer", marginLeft: "10px"}}>Save</button>
                </div>
            }

            <ReactModal isOpen={formView !== ""} style={modalCustomStyles}>
                <button onClick={onModalClose} style={closeButtonStyles}>
                    <span style={crossIconStyles}>&times;</span>
                </button>
                { formView === "addRestaurant" && !auth?.roles.includes("RESTAURANT_ADD") &&
                    <div style={{marginTop: "10px", marginBottom: "10px"}}>
                        <span>Permission Denied</span>
                    </div>
                }
                { formView === "addMenu" && !auth?.roles.includes("MENU_ADMIN") &&
                    <div style={{marginTop: "10px", marginBottom: "10px"}}>
                        <span>Permission Denied</span>
                    </div>                
                }
                { formView === "addMenu" && auth?.roles.includes("MENU_ADMIN") &&
                    <ModifyMenu defaultValues={{enabled: true, timings: restaurantData.openingHours}} onSave={handleAddMenu}/>
                }
                { formView === "editMenu" && auth?.roles.includes("MENU_ADMIN") &&
                    <ModifyMenu defaultValues={{enabled: updatedRestaurantData.menuSettings.filter((x: any)=>x.menuName === formViewId)[0].enabled, menuName: updatedRestaurantData.menuSettings.filter((x: any)=>x.menuName === formViewId)[0].menuName, timings: updatedRestaurantData.menuSettings.filter((x: any)=>x.menuName === formViewId)[0].timings}} onSave={handleEditMenu} editMode/>
                }
                { formView === "addCategory" && auth?.roles.includes("MENU_ADMIN") &&
                    <ModifyCategory onSave={handleAddCategory}/>
                }
                { formView === "editCategory" && auth?.roles.includes("MENU_ADMIN") &&
                    <ModifyCategory defaultValues={{id: formViewId, name: formViewName}} onSave={handleEditCategory} editMode/>
                }
                { formView === "addProduct" && auth?.roles.includes("MENU_ADMIN") &&
                    <ModifyProduct menu={updatedRestaurantData.menu} defaultValues={{category: selectedCategory}} onSave={handleAddProduct}/>
                }
                { formView === "editProduct" && auth?.roles.includes("MENU_ADMIN") &&
                    <ModifyProduct menu={updatedRestaurantData.menu} defaultValues={{productId: formViewId, productName: updatedRestaurantData.menu.filter((x: any) => x.productId === formViewId)[0].productName, productDescription: updatedRestaurantData.menu.filter((x: any) => x.productId === formViewId)[0].productDescription, productPrice: updatedRestaurantData.menu.filter((x: any) => x.productId === formViewId)[0].productPrice, productThumbnailUrl: updatedRestaurantData.menu.filter((x: any) => x.productId === formViewId)[0].productThumbnailUrl, category: updatedRestaurantData.menu.filter((x: any) => x.productId === formViewId)[0].category}} onSave={handleEditProduct} editMode/>
                }
            </ReactModal>
        </div>
        </DndProvider>
    )
}

export default MenuManagerPage;