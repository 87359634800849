import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../hooks/ThemeContext";

type Props = {
    onSave: any,
    defaultValues?: any,
    editMode?: boolean
}


const ModifyCategory = (props: Props) => {

    const { theme } = useTheme();


    const handleSave = (event: any) => {
        event.preventDefault(); // Prevent the default form submission
    
        // Access form elements directly using ref
        const form = formRef.current;

        let output_data: any = {};

        if (props.defaultValues && props.defaultValues.id) {
            output_data["id"] = props.defaultValues.id;
        }
    
        if (form) {
            // Get the values from the form fields
            for (let i = 0; i < form.elements.length; i++) {
                const field = form.elements[i] as HTMLInputElement; // Casting to a specific type

                // Check if the element is an input or any other field
                if (field.name) {
                    if (field.type === 'checkbox') {
                        output_data[field.name] = field.checked ? true : false;
                    } 
                    else {
                        output_data[field.name] = field.value 
                    }                
                }
            }
            
            props.onSave(output_data)
        
            // You can also reset the form if needed
            // form.reset(); // Optional: Reset the form after submission
        }
    };

    const formRef = useRef<HTMLFormElement | null>(null);


    return (
        <>
            <span style={{fontWeight: "bold"}}>{props.editMode ? "Edit Category" : "Add Category"}</span>
            <form ref={formRef} onSubmit={handleSave}>
                <div style={{display: "flex", flexDirection: "column", marginTop: "20px"}}>
                    

                    <label style={{marginTop: "0px"}}>Category Name</label>
                    <input name="name" type="text" style={{marginTop: "5px", padding: "10px", boxSizing: "border-box"}} defaultValue={props.defaultValues && props.defaultValues.name} required/>


                    <button type="submit" style={{backgroundColor: "royalblue", color: theme.cartButtonTextColor, border: "none", width: "100%", marginTop: "30px", marginRight: "20px", padding: "10px", boxSizing: "border-box", cursor: "pointer"}}>Submit</button>
                
                </div>
                
            </form>
        </>
    )
}

export default ModifyCategory;