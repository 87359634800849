import { CardNumberElement } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import app from "../Firebase";


class StripeService {

    private static generateRandomId() {
        // Generate two random letters
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const randomLetters = 
            letters.charAt(Math.floor(Math.random() * letters.length)) +
            letters.charAt(Math.floor(Math.random() * letters.length));
        
        // Generate two random digits
        const randomDigits = 
            Math.floor(Math.random() * 10) + 
            '' + 
            Math.floor(Math.random() * 10);
    
        // Combine letters and digits
        return randomLetters + randomDigits;
    }

    public static expressCheckout = async (stripe: any, paymentMethodId: string, cart: any, total: number, formData: any, test_mode: boolean, restaurantId: string) => {
        interface PaymentResponse {
            clientSecret: string;
            paymentIntentId: string;
            // Add other properties if needed
        }

        const functions = getFunctions(app, 'europe-west2');  // Ensure you've initialized Firebase in your app
        // connectFunctionsEmulator(functions, "127.0.0.1", 5001);

        if (!stripe) {
          return;
        }
        
        console.log("create payment intent")

    
        try {

            console.log("EMAIL ADDRESS IS:", formData.email);

          const processPayment = httpsCallable(functions, 'PPB');
        //   const confirmPayment = httpsCallable(functions, 'confirmPayment');
          const orderId = uuidv4();
          const orderCode = this.generateRandomId();

            return processPayment({ order_id: orderId, order_code: orderCode, restaurant_name: restaurantId, cart: cart, amount: total, currency: 'gbp', name: formData.name, email: formData.email, phone: formData.phone, payment_method_id: paymentMethodId, test_mode: test_mode}).then(response => {
                const clientSecret = (response.data as PaymentResponse).clientSecret;
                console.log("successful response received")

                return stripe?.confirmCardPayment(clientSecret).then(
                    (result: any) => {
                        console.log(result)
                    if (result.error) {
                        console.log(`Payment failed: ${result.error.message}`);
                        return {status: "FAILURE"}
                    } else if (result.paymentIntent?.status === 'succeeded') {
                        return {status: "SUCCESS", orderCode: orderCode};
                    }
                }).catch((error: any) => {
                    console.log(error)
                    return {status: "FAILURE"}
                })
            
                    
            }).catch((error: any) => {
                console.log("error PPB: ", JSON.stringify(error))
                return {status: "FAILURE"}
            })
                
    
        } catch (error) {
            console.log(`Payment failed: ${(error as Error).message}`);
            return {status: "FAILURE"}
        }
    

    };

    

    public static createPaymentIntent = async (stripe: any, elements: any, cart: any, total: number, formData: any, address: any, test_mode: boolean, restaurantId: string) => {
        interface PaymentResponse {
            clientSecret: string;
            paymentIntentId: string;
            // Add other properties if needed
        }

        const functions = getFunctions(app, 'europe-west2');  // Ensure you've initialized Firebase in your app
        // connectFunctionsEmulator(functions, "127.0.0.1", 5001);

        if (!stripe || !elements) {
          return;
        }
        
        const cardElement = elements.getElement(CardNumberElement)

    
        try {

            console.log("EMAIL ADDRESS IS:", formData.email);
            console.log("ADDRESS IS: ", address)

          const processPayment = httpsCallable(functions, 'PPB');
        //   const confirmPayment = httpsCallable(functions, 'confirmPayment');
          const orderId = uuidv4();
          const orderCode = this.generateRandomId();

            return processPayment({ order_id: orderId, order_code: orderCode, restaurant_name: restaurantId, cart: cart, amount: total, currency: 'gbp', name: formData.name, email: formData.email, phone: formData.phone, test_mode: test_mode}).then(response => {
                const clientSecret = (response.data as PaymentResponse).clientSecret;
                console.log("successful response received")

                return stripe?.confirmCardPayment(clientSecret, {
                    payment_method: { 
                        card: cardElement,
                        billing_details: {
                            name: formData.name,
                            email: formData.email,
                            address: address || undefined,
                        }
                    },
                }).then((result: any) => {
                    console.log(result)
                    if (result.error) {
                        console.log(`Payment failed: ${result.error.message}`);
                        return {status: "FAILURE"}
                    } 
                    else if (result.paymentIntent?.status === 'succeeded') {
                        return {status: "SUCCESS", orderCode: orderCode};
                    }
                }).catch((error: any) => {
                    console.log(error)
                    return {status: "FAILURE"}
                })
            
                    
            }).catch((error: any) => {
                console.log("error PPB: ", JSON.stringify(error))
                return {status: "FAILURE"}
            })
                
    
        } catch (error) {
            console.log(`Payment failed: ${(error as Error).message}`);
            return {status: "FAILURE"}
        }

      };

}

export default StripeService;

