import React, { useState } from "react";
import { useTheme } from "../../hooks/ThemeContext";
import { FaCog } from "react-icons/fa";
import { useDrag, useDrop } from "react-dnd";

type Props = {
    id: string,
    type: string,
    text: string,
    index: any,
    moveCard: any,
    onClick?: any,
    onEditClick?: any,
    style?: any,
    isSelected?: boolean,
    donotdisplay?: boolean
}


const ManagementCard = (props: Props) => {

    const theme = useTheme();

    const [{ isDragging }, drag] = useDrag({
        type: props.type,
        item: { id: props.id, index: props.index }, // Corrected syntax
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });
      
      const [, drop] = useDrop({
        accept: props.type,
        hover: (item: any) => {
          if (item.index !== props.index) {
            props.moveCard(item.index, props.index);
            item.index = props.index; // Update the dragged item's index
          }
        },
      });

    return (
        <div
        ref={(node) => drag(drop(node))}
         style={{          
            opacity: isDragging ? 0.1 : 1,
            display: props.donotdisplay ? "none" : "flex", alignItems: "center", justifyContent: "center", backgroundColor: theme.theme.sidebarBackgroundColor, border: "2px solid " + (props.isSelected ? theme.theme.sidebarIconColorSelected : theme.theme.borderColor), height: "30px", fontSize: "small", cursor: "move", userSelect: "none"}} onClick={() => {props.onClick && props.onClick(props.id)}} onMouseDown={() => {props.onClick && props.onClick(props.id)}}>
            <span style={{flexGrow: 1}}>{props.text} &nbsp;</span>
            {props.onEditClick && <span style={{display: "flex", alignItems: "center", backgroundColor: "black", fontSize: "small", color: "#ccc", padding: "3px", borderRadius: "10px", cursor: "pointer", marginRight: "10px"}} onClick={() => {props.onEditClick && props.onEditClick(props.id)}}><FaCog /></span>}
        </div>
    )
}

export default ManagementCard;