import { Route, Routes, useLocation } from "react-router-dom";
import RootPage from "./RootPage";
import OrderPage from "./components/OrderPage/OrderPage";
import CartPage from "./components/CartPage/CartPage";
import React from "react";
import DataService from "./service/DataService";
import { ThemeProvider } from "./hooks/ThemeContext";
import OrderConfirmationPage from "./components/CartPage/OrderConfirmationPage";
import Terms from "./components/Legal/Terms";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import { getConfig } from "./config/config";
import { useRestaurant } from "./hooks/RestaurantContext";

type Props = {
  testMode?: boolean
}


const RoutePageRoutes = (props: Props) => {

    const [productData, setProductData] = React.useState([]);
    const [menuSettings, setMenuSettings] = React.useState([]);
    const [openingHours, setOpeningHours] = React.useState([]);
    const [additionalCharges, setAdditionalCharges] = React.useState([]);
    const [activeUserId, setActiveUserId] = React.useState("");
    // const [cart, setCart] = React.useState(() => {
    //   const savedCart = localStorage.getItem("dp-appId-cart");
    //   return savedCart ? JSON.parse(savedCart) : [];
    // });
    const [cart, setCart] = React.useState<any>(() => {
      // Don't try to load from localStorage during initial render
      // since restaurantId might not be available yet
      return [];
    });

    const [showTopbar, setShowTopbar] = React.useState(false);
    const [showSidebar, setShowSidebar] = React.useState(false);
    const [homePageMode, setHomePageMode] = React.useState(false);

    const config = getConfig();

    // const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const restaurantId = queryParams.get("r") || config.defaultRestaurantId;
    const { restaurantId } = useRestaurant();

    // const [backgroundColor, setBackgroundColor] = React.useState("black");
    // const []

  // React.useEffect(() => {
  //   console.log("HOME PAGE MODE UPDATED: ", homePageMode)
  // }, [homePageMode])

  const setMenu = (e: any) => {
    console.log("restaurantID: " + JSON.stringify(restaurantId));
    setProductData(e.data.menu);
    setMenuSettings(e.data.menuSettings);
    setOpeningHours(e.data.openingHours);
    setAdditionalCharges(e.data.additionalCharges)
    
    // let localData = localStorage.getItem("dp-appId-cart") || "";
    // if (localData !== "") {
    //   setCart(JSON.parse(localData));
    // }
  }

  React.useEffect(() => {
    if (restaurantId){

      // DataService.addData("restaurants", myData, undefined, restaurantId)
      DataService.GetData("restaurants", restaurantId, undefined, setMenu).then((result) => {
          setProductData(result.menu);
          setMenuSettings(result.menuSettings);
          setOpeningHours(result.openingHours);
          setAdditionalCharges(result.additionalCharges);
      });

      const storageKey = `dp-appId-cart-${restaurantId}`;
    
      // Load initial cart data when restaurantId becomes available
      const savedCart = localStorage.getItem(storageKey);
      if (savedCart) {
        setCart(JSON.parse(savedCart));
      }

    }
  }, [restaurantId])

  React.useEffect(() => {
    if (restaurantId) {
      const storageKey = `dp-appId-cart-${restaurantId}`;
      localStorage.setItem(storageKey, JSON.stringify(cart));
    }
  }, [cart, restaurantId]);

  const [sidebarPages, setSidebarPages] = React.useState<any[]>([])

  React.useEffect(() => {

    let cartText = "Cart";
    if (cart.length > 0) {
      cartText = `Cart (${cart.length})`;
    }

    if (props.testMode) {
      setSidebarPages([
        {buttonText: "Home", url: process.env.REACT_APP_HOME_STORE_URL, iconName: "HiHome"},
        {buttonText: "Order", url: ["/test", "/test/pickup", "/test/dinein"], iconName: "HiPencilAlt"},
        {buttonText: "Find Us", url: "/test/location", iconName: "HiLocationMarker"},
        {buttonText: "Contact", url: "/test/contact", iconName: "HiPhone"},
        {buttonText: cartText, url: "/test/cart", iconName: "HiShoppingCart", hideInDesktopView: true}
      ])
    }
    else {
      setSidebarPages([
        {buttonText: "Home", url: process.env.REACT_APP_HOME_STORE_URL, iconName: "HiHome"},
        {buttonText: "Order", url: ["/", "/pickup", "/dinein"], iconName: "HiPencilAlt"},
        {buttonText: "Find Us", url: "/location", iconName: "HiLocationMarker"},
        {buttonText: "Contact", url: "/contact", iconName: "HiPhone"},
        {buttonText: cartText, url: "/cart", iconName: "HiShoppingCart", hideInDesktopView: true}
      ])
    }
  }, [cart])



    return (
      <ThemeProvider>
        <RootPage
            sidebarPages={sidebarPages}
        >


          <Routes>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            {/* <Route path="/" element={<SitesPage activeUserId={activeUserId} />} /> */}
            {/* <Route path="/order" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} /> */}
            <Route path="/" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/pickup" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/dinein" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/orderConfirmation" element={<OrderConfirmationPage />} />
            <Route path="/cart" element={<CartPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/location" element={
              <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d621.1618033371224!2d-0.3107067181138064!3d51.482989594751636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760db75834c5bd%3A0x300b26c8c6d11e30!2sUnit-1%2C%20Brentford%20Lock%2C%20High%20St%2C%20Brentford%20TW8%208AQ%2C%20UK!5e0!3m2!1sen!2sin!4v1718359389686!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
            } />
            <Route path="/contact" element={
              <div style={{display: "flex", flexDirection: "column", textAlign: "left", padding: "5%"}}>

                <h2 style={{fontWeight: "300"}}>CONTACT US</h2>
                <div style={{display: "flex"}}>
                  <span style={{fontWeight: "bold"}}>Address: </span><span style={{marginLeft: "10px"}}>High St Brentford Lock, Brentford, TW8 8LF</span>
                </div>
                <div style={{display: "flex", marginTop: "10px"}}>
                  <span style={{fontWeight: "bold"}}>Email: </span><span style={{marginLeft: "10px"}}>info@sultanslounge.co.uk</span>
                </div>

                <h2 style={{fontWeight: "300", marginTop: "40px"}}>OPENING HOURS</h2>
                <span style={{fontWeight: "bold"}}>Monday to Saturday</span>
                <span style={{marginTop: "10px"}}>11:00 - 14:30</span>
                <span style={{marginTop: "10px"}}>17:30 - 22:30</span>

                <span style={{marginTop: "10px", fontWeight: "bold"}}>Sunday</span>
                <span style={{marginTop: "10px"}}>18:00 - 23:30</span>
              </div>
            } />


          </Routes>

        </RootPage>
      </ThemeProvider>
    )
}

export default RoutePageRoutes;