interface Config {
    logoUrl: string;
    defaultFeaturedImageUrl: string;
    defaultRestaurantId: string;
    restaurants: any;
}
  
let runtimeConfig: Config;

export const loadConfig = async () => {
const response = await fetch("/config.json");
if (!response.ok) {
    throw new Error("Failed to load configuration");
}
runtimeConfig = await response.json();
};

export const getConfig = (): Config => {
if (!runtimeConfig) {
    throw new Error("Configuration not loaded. Call loadConfig() first.");
}
return runtimeConfig;
};