import React, { useState, useEffect, memo } from "react";
import { User, getAuth, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { SignInWithEmail, SignOut, SignUpWithEmail, processFirebaseError } from "../SignIn";
import Logo from "../Logo";
import { ThemeProvider, useTheme } from "../../hooks/ThemeContext";

interface ThemeColors {
  primary: string;
  background: string;
  surface: string;
  text: string;
  error: string;
}

interface LoginProps {
  redirectUrl: string;
  checkVerify?: boolean;
  theme?: Partial<ThemeColors>;
}

interface LoginFormProps {
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onSubmit: (e: React.FormEvent) => void;
  setView: (view: string) => void;
}

interface SignUpFormProps {
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onPasswordCheckChange: (value: string) => void;
  onSubmit: (e: React.FormEvent) => void;
}

const styles = {
  pageContainer: {
    height: "100vh",
    maxHeight: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(135deg, #f6f8fb 0%, #f5f5f5 100%)",
  },
  
  authContainer: {
    width: "100%",
    maxWidth: "420px",
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
    overflow: "hidden",
  },

  logoContainer: {
    width: "100%",
    height: "100px",
    paddingTop: "30px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#000000",
  },

  authContent: {
    padding: "32px",
    // boxSizing: "border-box" as "border-box"
  },

  title: {
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "24px",
    textAlign: "center" as const,
    color: "#2d3748",
  },

  form: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "16px",
  },

  input: {
    width: "100%",
    padding: "12px 16px",
    boxSizing: "border-box" as "border-box",
    fontSize: "16px",
    borderRadius: "8px",
    border: "1px solid #e2e8f0",
    transition: "all 0.2s ease",
    outline: "none",
  },

  button: {
    width: "100%",
    padding: "12px 24px",
    marginTop: "8px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#ffffff",
    backgroundColor: "#4F46E5",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "all 0.2s ease",
  },

  secondarybutton: {
    width: "100%",
    padding: "12px 24px",
    boxSizing: "border-box" as "border-box",
    fontSize: "16px",
    fontWeight: "500",
    color: "#4F46E5",
    backgroundColor: "white",
    border: "1px solid #4F46E5",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "all 0.2s ease",
    textAlign: "center" as "center"
  },

  toggleContainer: {
    display: "flex",
    borderRadius: "8px",
    overflow: "hidden",
    marginBottom: "24px",
    border: "1px solid #e2e8f0",
  },

  toggleButton: {
    flex: 1,
    padding: "12px",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    transition: "all 0.2s ease",
    fontSize: "14px",
    fontWeight: "500",
  },

  selectedToggle: {
    backgroundColor: "#4F46E5",
    color: "#ffffff",
  },

  linkText: {
    color: "#4F46E5",
    fontSize: "14px",
    cursor: "pointer",
    textAlign: "left" as const,
    marginTop: "8px",
    textDecoration: "none",
  },

  errorText: {
    color: "#e53e3e",
    fontSize: "14px",
    marginTop: "8px",
    textAlign: "center" as const,
  }
};

// Memoized form components
const LoginForm = memo(({ onEmailChange, onPasswordChange, onSubmit, setView }: LoginFormProps) => (
    <form style={styles.form} onSubmit={onSubmit}>
      <h1 style={styles.title}>Login</h1>
      <input
        style={styles.input}
        type="email"
        placeholder="Email Address"
        required
        onChange={(e) => onEmailChange(e.target.value)}
      />
      <input
        style={styles.input}
        type="password"
        placeholder="Password"
        required
        onChange={(e) => onPasswordChange(e.target.value)}
      />
      <div style={styles.linkText} onClick={() => setView("forgotPassword")}>
        Forgot Password?
      </div>
      <button type="submit" style={styles.button}>
        Login
      </button>
    </form>
  ));
  
  const SignUpForm = memo(({ onEmailChange, onPasswordChange, onPasswordCheckChange, onSubmit }: SignUpFormProps) => (
    <form style={styles.form} onSubmit={onSubmit}>
      <h1 style={styles.title}>Create Account</h1>
      <input
        style={styles.input}
        type="email"
        placeholder="Email Address"
        required
        onChange={(e) => onEmailChange(e.target.value)}
      />
      <input
        style={styles.input}
        type="password"
        placeholder="Password"
        required
        minLength={8}
        onChange={(e) => onPasswordChange(e.target.value)}
      />
      <input
        style={styles.input}
        type="password"
        placeholder="Confirm Password"
        required
        minLength={8}
        onChange={(e) => onPasswordCheckChange(e.target.value)}
      />
      <button type="submit" style={styles.button}>
        Sign Up
      </button>
    </form>
  ));
  
  const EmailVerificationForm = memo(({ email, onSubmit }: { email: string | null | undefined; onSubmit: (e: React.FormEvent) => void }) => (
    <form style={styles.form} onSubmit={onSubmit}>
      <h1 style={styles.title}>Verify Your Email</h1>
      <p style={{ textAlign: "center", marginBottom: "16px" }}>
        Click the button below to send a verification email to {email}
      </p>
      <button type="submit" style={styles.button}>
        Send Verification Email
      </button>
      <div style={styles.secondarybutton} onClick={() => SignOut("/")}>
        Log Out and Return
      </div>
    </form>
  ));

  const EmailVerificationMessage = () => {
    return (
        <form style={styles.form} onSubmit={()=>(window.location.href="/auth/verify")}>
            <h1 style={styles.title}>Email Verification</h1>
            <span style={{fontSize: "small", marginBottom: "5%"}}>A verification link has been sent to your email. Please click it to activate your account.</span>
            <button type="submit" style={styles.button}>
                Done
            </button>    
            <div style={styles.secondarybutton} onClick={() => SignOut("/")}>
                Log Out and Return
            </div>        
        </form>
    )
}
  
  const ForgotPasswordForm = memo(({ onEmailChange, onSubmit }: { onEmailChange: (value: string) => void; onSubmit: (e: React.FormEvent) => void }) => (
    <form style={styles.form} onSubmit={onSubmit}>
      <h1 style={styles.title}>Reset Password</h1>
      <p style={{ textAlign: "center", marginBottom: "16px" }}>
        Enter your email address to receive a password reset link
      </p>
      <input
        style={styles.input}
        type="email"
        placeholder="Email Address"
        required
        onChange={(e) => onEmailChange(e.target.value)}
      />
      <button type="submit" style={styles.button}>
        Send Reset Link
      </button>
    </form>
  ));
  
  const AuthToggle = memo(({ view, setView }: { view: string; setView: (view: string) => void }) => (
    <div style={styles.toggleContainer}>
      <button
        style={{
          ...styles.toggleButton,
          ...(view === "login" ? styles.selectedToggle : {})
        }}
        onClick={() => setView("login")}
      >
        Login
      </button>
      <button
        style={{
          ...styles.toggleButton,
          ...(view === "signUp" ? styles.selectedToggle : {})
        }}
        onClick={() => setView("signUp")}
      >
        Sign Up
      </button>
    </div>
  ));
  
  const Login: React.FC<LoginProps> = ({ redirectUrl, checkVerify = false, theme = {} }) => {
    const [view, setView] = useState<string>("login");
    const [errors, setErrors] = useState<string[]>([]);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordCheck, setPasswordCheck] = useState<string>("");
    const [user, setUser] = useState<User | null>(null);

    const siteTheme = useTheme();
  
    useEffect(() => {
      setErrors([]);
    }, [view]);
  
    useEffect(() => {
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
          if (!user.emailVerified) {
            setView("emailVerificationForm");
          } else {
            setView("loggedIn");
            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              window.location.href = "/";
            }
          }
        }
      });
      return () => unsubscribe();
    }, [redirectUrl]);
  
    const sendVerificationEmail = (e: React.FormEvent) => {
      e.preventDefault();
      if (user && !user.emailVerified) {
        const hostUrl = window.location.hostname.includes("localhost")
          ? undefined
          : `https://${window.location.hostname}`;
        
        sendEmailVerification(user, hostUrl ? { url: hostUrl } : undefined);
        setView("emailVerificationMessage");
      }
    };
  
    const sendPasswordResetLink = (e: React.FormEvent) => {
      e.preventDefault();
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => setView("passwordResetMessage"))
        .catch((error) => setErrors(["ERROR: " + processFirebaseError(error.message)]));
    };
  
    const executeLogin = (e: React.FormEvent) => {
      e.preventDefault();
      SignInWithEmail(email, password, "/").then((result) => {
        if (result === "NOT_VERIFIED") {
          setView("emailVerificationForm");
        }
        if (result.includes("ERROR")) {
          setErrors([result]);
        }
      });
    };
  
    const executeSignUp = (e: React.FormEvent) => {
      e.preventDefault();
      if (password === passwordCheck) {
        SignUpWithEmail(email, password).then((result) => {
          if (result.includes("ERROR")) {
            setErrors([result]);
          }
        });
      } else {
        setErrors(["ERROR: Passwords do not match"]);
      }
    };
  
    const ErrorMessages = () => (
      <>
        {errors.map((error, index) => (
          <p key={index} style={styles.errorText}>
            {error}
          </p>
        ))}
      </>
    );
  
    return (
      <div style={{display: "flex", width: "100%", height: "100vh", alignItems: "center", justifyContent: "center", backgroundColor: "#101014"}}>
        <div style={styles.authContainer}>
          {/* <div style={styles.logoContainer}>
            <Logo />
          </div> */}
          <div style={styles.authContent}>
            {!user && <AuthToggle view={view} setView={setView} />}
            {view === "login" && (
              <LoginForm
                onEmailChange={setEmail}
                onPasswordChange={setPassword}
                onSubmit={executeLogin}
                setView={setView}
              />
            )}
            {view === "signUp" && (
              <SignUpForm
                onEmailChange={setEmail}
                onPasswordChange={setPassword}
                onPasswordCheckChange={setPasswordCheck}
                onSubmit={executeSignUp}
              />
            )}
            {view === "forgotPassword" && (
              <ForgotPasswordForm
                onEmailChange={setEmail}
                onSubmit={sendPasswordResetLink}
              />
            )}
            {view === "emailVerificationForm" && (
              <EmailVerificationForm
                email={user?.email}
                onSubmit={sendVerificationEmail}
              />
            )}
            {view === "emailVerificationMessage" && (
              <EmailVerificationMessage />
            )}
            {
                view !== "login" && view !== "signUp" && view !== "forgotPassword" && view !== "emailVerificationForm" && view !== "emailVerificationMessage" && 
                <>
                    <h1 style={styles.title}>Redirecting...</h1>
                </>
            }
            <ErrorMessages />
          </div>
        </div>
      </div>
    );
  };
  
  export default Login;