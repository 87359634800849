// import firestore from '@react-native-firebase/firestore';
import { FIREBASE_FIRESTORE } from "../Firebase";
import { useAuth } from "../hooks/useAuth";
import { User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

class UserService {

    public static GetUserRoles = async (user: User) => {
        try {
            const userDocRef = doc(FIREBASE_FIRESTORE, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
        
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                return (userData.permissions);

            } else {
                console.log("No such document!");
                return [];
            }
            } 
        catch (error) {
            console.error("Error fetching user document:", error);
            return [];
        }
        
    };
      

}

export default UserService;