import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import app from "../Firebase";


class TimeService {

    private static timeToMinutesSinceMidnight(timeStr: string) {

        const [hours, minutes] = timeStr.split(':').map(Number);
              return hours * 60 + minutes;
    }

    public static async getCurrentTimeDetails(): Promise<{ day: string; minutesSinceMidnight: number; currentTime: string } | null> {
        const localTime = sessionStorage.getItem('localTime');
        if (localTime) {
          const timeDiff = parseFloat(sessionStorage.getItem('timeDiff') || '0');
          const currentTime = new Date(localTime);
          const adjustedTime = new Date(currentTime.getTime() + timeDiff * 1000 + (new Date().getTime() - new Date(localTime).getTime()));
      
          // Get day of the week
          const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
          const day = adjustedTime.toLocaleDateString('en-US', options); // Day of the week
      
          // Get number of minutes since midnight
          const minutesSinceMidnight = adjustedTime.getHours() * 60 + adjustedTime.getMinutes();
      
          // Return the results
          console.log("local time successfully processed")
          return {
            day,
            minutesSinceMidnight,
            currentTime: adjustedTime.toLocaleTimeString() // Current time in HH:MM:SS format
          };
        } else {
          try {
            const functions = getFunctions(app, 'europe-west2');
            const getLocalTimeObject = httpsCallable(functions, 'getLocalTimeObject');

            console.log("Weird stuff: ", "2024-10-28T01:23:22Z", new Date("2024-10-28T01:23:22Z"))
        
            const result: any = await getLocalTimeObject();
            const merchantTime: string = result.data.split("Z")[0];
            const deviceTime = new Date().toISOString();
            const merchantTimeDate = new Date(merchantTime);
            const deviceTimeDate = new Date(deviceTime);
            const timeDiff = (merchantTimeDate.getTime() - deviceTimeDate.getTime()) / 1000; 
            sessionStorage.setItem('localTime', merchantTime);
            sessionStorage.setItem('timeDiff', timeDiff.toString());
      
            const currentTime = new Date(merchantTime);
            const adjustedTime = new Date(currentTime.getTime() + timeDiff * 1000 + (new Date().getTime() - new Date(merchantTime).getTime()));
  

            



            // Get day of the week
            const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
            const day = adjustedTime.toLocaleDateString('en-US', options); // Day of the week
      
            // Get number of minutes since midnight
            const minutesSinceMidnight = adjustedTime.getHours() * 60 + adjustedTime.getMinutes();
      
            // Return the results
            console.log("local time successfully processed from server")
            return {
              day,
              minutesSinceMidnight,
              currentTime: adjustedTime.toLocaleTimeString() // Current time in HH:MM:SS format
            };
          } catch (error) {
            console.error("Error fetching current time:", error);
            throw error;
          }
        }
      }


    public static checkActiveMenu = async (menuSettings: any, menuName: string, timeObj?: any ) => {
        console.log("check active menu call")
        try{
        
            let timeObject: any;

            if (timeObj) {
                // console.log("existing")
                timeObject = timeObj
            }
            else {
                // console.log("new")
                timeObject = await TimeService.getCurrentTimeDetails();
            }

            if (timeObject) {
                // console.log("TimeObj1: ", timeObject)
                let day = timeObject.day.toLocaleLowerCase();
                let minutesSinceMidnight = timeObject.minutesSinceMidnight;

                let menuData = menuSettings.filter((item: any) => item.menuName === menuName)[0];
                // console.log(menuData)
                console.log("TIME SECTION")
                let menuStart;
                let menuEnd;

                if (typeof menuData.timings[day].start === "string") {
                    menuStart = this.timeToMinutesSinceMidnight(menuData.timings[day].start)
                    menuEnd = this.timeToMinutesSinceMidnight(menuData.timings[day].end)
                }
                else {
                    menuStart = menuData.timings[day].start;
                    menuEnd = menuData.timings[day].end;
                }

                // console.log(menuStart, menuEnd, minutesSinceMidnight)

                if (minutesSinceMidnight >= menuStart && minutesSinceMidnight < menuEnd) {
                    return true;
                }

                return false;
            }
            else {
                console.log("ITEM ACCEPTED BUT ERROR GETTING TIME")
                return true;
            }
        }
        catch (error){
            console.log("ERROR BUT RETURNING: ", error)
            return false;
        }
    }

    public static checkAnyMenuActive = async (menuSettings: any, menuNames: string[]) => {

        try{

            let timeObject = await TimeService.getCurrentTimeDetails();
            console.log("TimeObj2: ", timeObject)

            for (let menuName of menuNames) {
                if (await this.checkActiveMenu(menuSettings, menuName, timeObject)) {
                    return true;
                }
            }

            return false;

        }
        catch {
            return false;
        }
    
    }

    public static getAllowedMenus = async (menuSettings: any) => {

        try {

            let timeObject = await TimeService.getCurrentTimeDetails();
            console.log("TimeObj3: ", timeObject)
            let outputMenus: string[] = []

            for (let menu of menuSettings) {
                if (await this.checkActiveMenu(menuSettings, menu.menuName, timeObject)) {
                    outputMenus.push(menu.menuName)
                }
            }

            return outputMenus;
        }
        catch {
            console.log("Error so allowing all.")
            return menuSettings.map((menu: any) => menu.menuName)
        }
    }

    public static checkStoreIsOpen = async (openingHours: any, timeObj?: any ) => {
        try{
        
            let timeObject: any;

            if (timeObj) {
                timeObject = timeObj
            }
            else {
                timeObject = await TimeService.getCurrentTimeDetails();
            }

            if (timeObject) {
                console.log("TimeObj1: ", timeObject)
                let day = timeObject.day.toLocaleLowerCase();
                let minutesSinceMidnight = timeObject.minutesSinceMidnight;

                let menuStart;
                let menuEnd;

                if (typeof openingHours[day].start === "string") {
                    console.log("Opening hours are in string format")
                    menuStart = this.timeToMinutesSinceMidnight(openingHours[day].start)
                    menuEnd = this.timeToMinutesSinceMidnight(openingHours[day].end)
                }
                else {
                    console.log("Opening hours are not in string format")
                    menuStart = openingHours[day].start;
                    menuEnd = openingHours[day].end;
                }

                console.log(menuStart, menuEnd)

                if (minutesSinceMidnight >= menuStart && minutesSinceMidnight < menuEnd) {
                    return true;
                }

                return false;
            }
            else {
                console.log("ITEM ACCEPTED BUT ERROR GETTING TIME")
                return true;
            }
        }
        catch {
            console.log("ERROR BUT RETURNING")
            return true;
        }
    }


}

export default TimeService;