import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import RoutePageRoutes from "./RoutePageRoutes";
import Login from "./components/Authentication/Login";
import { ThemeProvider } from "./hooks/ThemeContext";
import { AuthProvider } from "./hooks/useAuth";
import RoutePageAdminRoutes from "./RoutePageAdminRoutes";
import Modal from "react-modal";
import { getConfig, loadConfig } from "./config/config";
import { RestaurantProvider, useRestaurant } from "./hooks/RestaurantContext";

const App = () => {
  Modal.setAppElement("#root");
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        await loadConfig();
        setIsConfigLoaded(true);
      } catch (err) {
        console.error("Failed to load configuration:", err);
        setError(err as Error);
      }
    };

    fetchConfig();
  }, []);

  if (error) {
    return (
      <div>
        <h1>Error Loading Configuration</h1>
        <p>{error.message}</p>
      </div>
    );
  }

  if (!isConfigLoaded) {
    return <div>Loading configuration...</div>;
  }

  return (
    <BrowserRouter>
      <RestaurantProvider>
        <RestaurantInitializer />
        <ThemeProvider>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login redirectUrl="/" />} />
              <Route path="/admin/*" element={<RoutePageAdminRoutes />} />
              <Route path="/test/*" element={<RoutePageRoutes testMode={true} />} />
              <Route path="*" element={<RoutePageRoutes />} />
            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </RestaurantProvider>
    </BrowserRouter>
  );
};

const RestaurantInitializer: React.FC = () => {
  const { setRestaurantId } = useRestaurant();
  const location = useLocation();

  useEffect(() => {
    const config = getConfig();
    const queryParams = new URLSearchParams(location.search);
    const restaurantId = queryParams.get("r") || config.defaultRestaurantId;

    setRestaurantId(restaurantId); // Set the restaurantId in the context
  }, [location, setRestaurantId]);

  return null; // This component only initializes the context
};

export default App;
