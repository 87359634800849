import { AddressElement, CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useTheme } from "../../../../hooks/ThemeContext";
import ClipLoader from "react-spinners/ClipLoader";
import StripeService from "../../../../service/StripeService";
import { toast } from "react-toastify";
import Select from 'react-select';
import { getNames, getCode } from 'country-list';
import TimeService from "../../../../service/TimeService";
import ToastService from "../../../../service/ToastService";
import { useRestaurant } from "../../../../hooks/RestaurantContext";

type Props = {
    stripe: any,
    elements: any,
    order: any,
    setCart: any,
    amount: number,
    formData: any,
    onBack: any,
    onSuccessReturn: any,
    updateQuantity: any,
    menuSettings: any,
    openingHours: any,
    testMode: boolean
}


const CardDetails = (props: Props) => {

    const { theme } = useTheme();
    const { restaurantId } = useRestaurant();
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState<string[]>([]); // Array of error messages
    const [cardComplete, setCardComplete] = useState({
        numberComplete: false,
        expiryComplete: false,
        cvcComplete: false
    });

    const [address, setAddress] = useState({
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "GB",
      postal_code: "",
    })

    const handleCardChange = (event: any, field: string) => {
        setCardComplete((prev) => ({
          ...prev,
          [`${field}Complete`]: event.complete,
        }));
    };

    const handleAddressChange = (event: any, field: string) => {
      console.log(event.target.value)
      setAddress((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

    const validateForm = () => {
        let errors: string[] = [];
        let valid = true;
    
    
        // Check if all card elements are complete
        if (!cardComplete.numberComplete || !cardComplete.expiryComplete || !cardComplete.cvcComplete) {
          errors.push("Please complete your card details.");
          valid = false;
        }

        // Check if all card elements are complete
        if (!address.country || !address.city || !address.line1 || !address.postal_code) {
          errors.push("Please complete your billing address.");
          valid = false;
        }

        if (address.country === "US" && !address.state) {
          errors.push("Please complete your billing address.");
          valid = false;
        }
    
        setFormErrors(errors);
        return valid;
      };


    const onPaymentSubmit = async () => {

      let storeOpen = await TimeService.checkStoreIsOpen(props.openingHours);

      if (!storeOpen) {
        props.onSuccessReturn();
        ToastService.showToast(`Store is currently closed!`, {
          style: {
              backgroundColor: "darkred", // Set the toast background color to green
              color: "white",
              minHeight: "80px",
              borderRadius: "15px",
          },
        });
        return;
      }
      else {

        let allowedMenus = await TimeService.getAllowedMenus(props.menuSettings);
        let updatedCart = false;
  
        for (let orderItem of props.order) {
          let menuFound = false;
          for (let menuItem of orderItem.menus) {
              if (allowedMenus.includes(menuItem)) {
                  menuFound = true;
              }
          }
          if (menuFound == false) {
              updatedCart = true;
              props.updateQuantity(orderItem, 0);
              props.onSuccessReturn();
              ToastService.showToast(`Unavailable items have been removed from your cart. Please verify your cart before ordering!`, {
                style: {
                    backgroundColor: "darkred", // Set the toast background color to green
                    color: "white",
                    minHeight: "80px",
                    borderRadius: "15px",
                },
              });
              return;
          }
        }

        if (!validateForm()) {
          return;
        }
    
        setLoading(true);
        const result = await StripeService.createPaymentIntent(
          props.stripe,
          props.elements,
          props.order,
          props.amount,
          props.formData,
          address,
          props.testMode,
          restaurantId
        );
        setLoading(false);
    
        if (result.status === "SUCCESS") {
          console.log("successful. ", props.order)
          localStorage.setItem(`order_${result.orderCode}`, JSON.stringify(props.order))
          const newWindow = window.open(`/orderConfirmation?id=${result.orderCode}`, '_blank', 'width=600,height=800');
          if (!newWindow) {
            alert(`Your order was successful. Your order code is ${result.orderCode}. You will receive an email receipt.`)
          }
          props.setCart([]);
          props.onSuccessReturn();
          ToastService.showToast("Payment Successful!", {
            style: {
                backgroundColor: "darkgreen", // Set the toast background color to green
                color: "white", // White text for contrast
            },
          });
        } else {
          console.log("Payment failed");
          ToastService.showToast("Payment Failed!", {
            style: {
                backgroundColor: "darkred", // Set the toast background color to green
                color: "white",           // White text for contrast
            },
          });
          setLoading(false);
        }
      }
    };

    const hitBack = (e: any) => {
      e.preventDefault();
      props.onBack();
    }

    const countries = getNames().map((country) => ({
      label: country,
      value: getCode(country),
    }));
  
    const handleCountryChange = (selectedOption: any) => {
      handleAddressChange(selectedOption, "country");
    };


  //   {
  //     "line1": "123 Main Street",
  //     "line2": "Apartment 4B",  // Optional
  //     "city": "London",
  //     "state": "England",  // Optional (Not required in the UK)
  //     "postal_code": "EC1A 1BB",
  //     "country": "GB"  // ISO country code (e.g., 'GB' for the UK)
  // }

    return (

        <div style={{display: "flex", flexDirection: "column"}}>

            <button
              style={{
                width: "50px",
                cursor: "pointer",
                backgroundColor: theme.cartButtonColor,
                color: theme.cartButtonTextColor,
                border: "none",
                borderRadius: "10px",
                padding: "5px",
              }}
              onClick={hitBack}
            >
              Back
            </button>

            <p style={{ fontWeight: "bold", marginTop: "30px" }}>Billing Details</p>

            <label style={{marginTop: "10px"}}>Country</label>
              <select
                id="country-select"
                value={address.country}
                onChange={handleCountryChange}
                style={{
                  marginTop: "5px",
                  padding: "5px",
                  border: "1px solid grey",
                  borderRadius: "10px",
                  background: "none",
                  color: theme.cartTextColor,
                  boxSizing: "border-box"
                }}
              >
                <option value="">--Please choose a country--</option>
                {countries.map((country) => (
                  <option key={country.value} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </select>

            {address.country !== "" && <>

            <label style={{marginTop: "10px"}}>Address Line 1</label>
            <input
              name="line1"
              value={address.line1}
              onChange={(e) => handleAddressChange(e, "line1")}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            />
    
            <label style={{marginTop: "10px"}}>Address Line 2</label>
            <input
              name="line2"
              value={address.line2}
              onChange={(e) => handleAddressChange(e, "line2")}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            />
    
            <label style={{marginTop: "10px"}}>City</label>
            <input
              name="city"
              value={address.city}
              onChange={(e) => handleAddressChange(e, "city")}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            />

            {address.country === "US" && <><label style={{marginTop: "10px"}}>State</label>
            <input
              name="city"
              value={address.state}
              onChange={(e) => handleAddressChange(e, "state")}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            /> </>}



            <label style={{marginTop: "10px"}}>Postal Code</label>
            <input
              name="city"
              value={address.postal_code}
              onChange={(e) => handleAddressChange(e, "postal_code")}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            /> 
            
            </>}
  

            <label style={{marginTop: "30px"}}>Card Number</label>
            <div
                style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                color: theme.cartTextColor,
                }}
            >
                <CardNumberElement
                onChange={(e) => handleCardChange(e, "number")}
                options={{style: {base: {color: theme.cartTextColor}}}}
                />
            </div>

            <div style={{ display: "flex", flexDirection: "row", marginTop: "10px", marginBottom: "10px"  }}>
                <div style={{ width: "50%"}}>
                <label>Expiry Date</label>
                <div
                    style={{
                    height: "20px",
                    marginTop: "5px",
                    padding: "5px",
                    border: "1px solid grey",
                    borderRadius: "10px",
                    }}
                >
                    <CardExpiryElement
                    onChange={(e) => handleCardChange(e, "expiry")}
                    options={{style: {base: {color: theme.cartTextColor}}}}
                    />
                </div>
                </div>

                <div style={{ width: "50%" }}>
                <label style={{ marginLeft: "20px" }}>CVC</label>
                <div
                    style={{
                    height: "20px",
                    marginTop: "5px",
                    padding: "5px",
                    border: "1px solid grey",
                    borderRadius: "10px",
                    marginLeft: "20px",
                    }}
                >
                    <CardCvcElement 
                        onChange={(e) => handleCardChange(e, "cvc")} 
                        options={{style: {base: {color: theme.cartTextColor}}}}
                    />
                </div>
                </div>
            </div>

            {loading ? (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <ClipLoader color="#3498db" loading={loading} size={20} />
              </div>
            ) : (
              <button
                style={{
                  height: "40px",
                  marginTop: "20px",
                  width: "100%",
                  cursor: "pointer",
                  backgroundColor: theme.payButtonColor,
                  color: theme.payButtonTextColor,
                  border: "none",
                  borderRadius: "10px",
                }}
                onClick={onPaymentSubmit}
              >
                Pay
              </button>
            )}

            {/* Error list */}
            {formErrors.length > 0 && (
            <div style={{ color: "red" }}>
                <ul>
                {formErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
                </ul>
            </div>
            )}

        </div>

    )
}

export default CardDetails;