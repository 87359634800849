import React, { useEffect, useState } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithCustomToken, signInWithCredential, verifyBeforeUpdateEmail, sendEmailVerification } from "firebase/auth";
import signInImage from "../img/signin_google.png"
import "../styles/SignIn.css"
import "../mobile_styles/SignIn.css"
import jwtDecode from "jwt-decode";
import { auth } from "firebase-admin";
import { emit } from "process";
import { HiChevronDown, HiUser } from "react-icons/hi";
import { useAuth } from "../hooks/useAuth";
import ToastService from "../service/ToastService";

type Props = {
    setActiveUserId: any
}

export const processFirebaseError = (error: string) => {
    let processed = error.split("/")[1].split(")")[0].replaceAll("-", " ");
    let output = processed.charAt(0).toUpperCase() + processed.slice(1);

    return output;
}

export const SignInWithGoogle = (redirectUrl: string) => {

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);

        if (redirectUrl != ""){
            // window.self.close();
            window.location.href = redirectUrl;
        }


        //@ts-ignore
        const token = credential.accessToken;
        const user = result.user;

    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
    });

}

export const SignInWithEmailPopup = () => {
    window.location.href="/auth"
}

export const SignInWithEmail = (email: string, password: string, redirectUrl = "") => {

    const auth = getAuth();
    const provider = new GoogleAuthProvider();


    return signInWithEmailAndPassword(auth, email, password).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);


        //@ts-ignore
        // const token = credential.accessToken;
        const user = result.user;

        if (user.emailVerified){
            if (redirectUrl != ""){
                window.self.close();
                // window.location.href = redirectUrl;
            }
            return "SUCCESS";
        }
        else {
            return "NOT_VERIFIED";
        }

    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        return "ERROR: " + processFirebaseError(errorMessage);
    });

}

export const SignUpWithEmail = (email: string, password: string) => {

    const auth = getAuth();
    const provider = new GoogleAuthProvider();


    

    

    return createUserWithEmailAndPassword(auth, email, password).then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);

        // if (redirectUrl != ""){
        //     window.location.href = redirectUrl;
        // }


        //@ts-ignore
        // const token = credential.accessToken;
        let authtoken = result._tokenResponse.idToken
        const user = result.user;

        if (user) {
            return "SUCCESS"
        }
        else {
            return "USER_NOT_LOADED"
        }

        

    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        console.log(errorMessage)
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        return "ERROR: " + processFirebaseError(errorMessage);
    });

}

export const SignOut = (redirect?: string) => {
    const auth = getAuth();
    signOut(auth);
    ToastService.showToast("Logged Out", {
        style: {
            backgroundColor: "darkred", // Set the toast background color to green
            color: "white",
            minHeight: "80px",
            borderRadius: "15px",
        },
    });
    window.location.href = redirect || "/login";
}


export const SignIn = (props: Props) => {

    const [username, setUsername] = useState("");
    const [fullname, setFullname] = useState("");
    const [displayImg, setDisplayImg] = useState("https://upload.wikimedia.org/wikipedia/commons/9/9a/No_avatar.png")
    const [showProfileMenu, setShowProfileMenu] = React.useState(false);
    const authHook = useAuth();
    const [adminUser, setAdminUser] = useState(false);

    useEffect(() => {
        setUsername(localStorage.getItem("loggedInUserEmail")!);
    }, [])

    useEffect(() => {
        if (authHook?.roles.includes("ADMIN")) {
            setAdminUser(true);
        }
        else {
            setAdminUser(false);
        }
    }, [authHook?.roles])


    const SignOut = () => {
        const auth = getAuth();
        signOut(auth);
        ToastService.showToast("Logged Out", {
            style: {
                backgroundColor: "darkred", // Set the toast background color to green
                color: "white",
                minHeight: "80px",
                borderRadius: "15px",
            },
        });
        window.location.href="/";
    }

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user){
                if (user.emailVerified){
                    setUsername(user.email || "");
                    setFullname(user.displayName || "");
                    setDisplayImg(user.photoURL || "");
                    props.setActiveUserId(user.uid || "");
                    localStorage.setItem("loggedInUserEmail", user.email || "");
                    localStorage.setItem("loggedInUserId", user.uid || "");
                    ToastService.showToast("Signed In Successfully", {
                        autoClose: 500,
                        style: {
                            backgroundColor: "darkgreen", // Set the toast background color to green
                            color: "white",
                            minHeight: "80px",
                            borderRadius: "15px",
                        },
                    });
                    // const getToken = async () => {
                    //     const authToken = user ? await user.getIdToken() : "";
                    //     const decodedToken: any = jwtDecode(authToken);
                    //     const expirationTime = new Date(decodedToken.exp * 1000);
                    //     localStorage.setItem("loggedInIdToken", authToken)
                    //     localStorage.setItem("tokenExpiration", expirationTime.toString());
                    // }
                    // getToken();
                }    
                else {
                    window.location.href = "/login";
                }            
            }
            else {
                setUsername("");
                setFullname("");
                setDisplayImg("https://upload.wikimedia.org/wikipedia/commons/9/9a/No_avatar.png");
                props.setActiveUserId("");
                localStorage.setItem("loggedInUserEmail", "");
                localStorage.setItem("loggedInUserId", "");
            }
        })
    }, [])


    return (
        <div className="auth-button" style={{fontSize: "x-large", height: "100%", color: "white"}} onMouseEnter={()=>setShowProfileMenu(true)} onMouseLeave={()=>setShowProfileMenu(false)} onClickCapture={() => {}}>
            <span className="user-icon" style={{}}><HiUser /> <HiChevronDown /></span>
            {/* <img style={{cursor: "pointer"}} src={signInImage} onClick={() => {SignInWithGoogle("")}}/> */}
        
            {showProfileMenu && 
                <div className="dropdown-menu" style={{backgroundColor: "white"}}>
                    {
                        username === "" && 

                        <>
                            <a href="/login" style={{color: "black"}}>Login</a>
                            <a href="/login?register=true" style={{color: "black"}}>Register</a>
                        </>
                    }
                    {
                        username !== "" && 

                        <>
                            {/* <a href="/profile" style={{color: "black"}}>Profile</a> */}

                            {adminUser && 
                                <div className="dropdown-menu-item" onClick={()=>window.location.href = "/admin/console"} style={{color: "black"}}>Admin Console</div>
                            }
                            <div className="dropdown-menu-item" onClick={()=>SignOut()} style={{color: "black"}}>Log Out</div>
                        </>
                    }
                </div>
            }

        </div>
    )

    


    if (username === "") {

        return (
            <div className="auth-button" style={{fontSize: "x-large", height: "100%"}}>
                <span className="user-icon"><HiUser /> <HiChevronDown /></span>
                {/* <img style={{cursor: "pointer"}} src={signInImage} onClick={() => {SignInWithGoogle("")}}/> */}
            </div>
        )
    }
    else {
        return(
            <div className="profile-contents">

                <div className="user-details">
                    <span>{username}</span>
                </div>

                <div className="auth-button app-primary-color" 
                    style={{cursor: "pointer", textDecoration: "underline", fontSize: "smaller"}} 
                    onClick={() => {SignOut()}}><span>Sign Out</span>
                </div>

            </div>
        )
    }


}

export default SignIn;