import { useState, useEffect, useContext, createContext } from 'react';
import { onAuthStateChanged, User } from "firebase/auth";
import { FIREBASE_AUTH } from '../Firebase';
import React from 'react';
import UserService from '../service/UserService';


interface AuthContextType {
    user: User | null;
    roles: string[]; // Assuming roles is an array of strings
  }

// Create a context for the Auth state
const AuthContext = createContext<AuthContextType | null>(null);

// Custom hook to provide access to the user state
export const useAuth = () => {
  return useContext(AuthContext);
};

type Props = {
    children: React.ReactNode,
}

// AuthProvider component that wraps your app and provides user state
export const AuthProvider = (props: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, async (user) => {
      setUser(user);

      if (user) {
        console.log("Auth hook: user is logged on.")
        try {
            const roleRetrieval = await UserService.GetUserRoles(user);
            setRoles(roleRetrieval);
        } 
        catch (error) {
            console.error("Error fetching roles:", error);
        }
    }
    else {
        console.log("Auth hook: User is not logged in");
    }

      setLoading(false);  // Set loading to false once user is determined
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

//   if (loading) {
//     return <ThemedText>Loading...</ThemedText>; // Optionally show a loading state
//   }

  return (
    <AuthContext.Provider value={{ user, roles }}>
      {props.children}
    </AuthContext.Provider>
  );
};
