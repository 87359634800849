import { Route, Routes, useLocation } from "react-router-dom";
import RootPage from "./RootPage";
import OrderPage from "./components/OrderPage/OrderPage";
import CartPage from "./components/CartPage/CartPage";
import React, { useEffect, useState } from "react";
import DataService from "./service/DataService";
import { ThemeProvider, useTheme } from "./hooks/ThemeContext";
import OrderConfirmationPage from "./components/CartPage/OrderConfirmationPage";
import Terms from "./components/Legal/Terms";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import { useAuth } from "./hooks/useAuth";
import MenuManagerPage from "./components/MenuManager/MenuManagerPage";
import Spinner from "./components/Spinner/Spinner";
import { getConfig } from "./config/config";
import { useRestaurant } from "./hooks/RestaurantContext";

type Props = {
  testMode?: boolean
}

const RoutePageAdminRoutes = (props: Props) => {

    const [cart, setCart] = React.useState<any[]>([]);

    const config = getConfig();

    const { restaurantId } = useRestaurant();

    const auth = useAuth();
    const [view, setView] = useState(false)

    useEffect(() => {
      if (auth?.roles.includes("ADMIN")) {
        setView(true);
      }
      else {
        setView(false);
      }
    }, [auth?.roles])

  const [sidebarPages, setSidebarPages] = React.useState<any[]>([])

  React.useEffect(() => {

    if (props.testMode) {
      setSidebarPages([])
    }
    else {
      setSidebarPages([
        {buttonText: "Home", url: process.env.REACT_APP_HOME_STORE_URL, iconName: "HiHome"},
        {buttonText: "Order", url: "/", iconName: "HiPencilAlt"},
        {buttonText: "Menus", url: "/admin/console", iconName: "HiMenu"}
      ])
    }
  }, [cart])


  
  return (
    <ThemeProvider mode={"dark"}>
      <RootPage
          sidebarPages={sidebarPages}
          logo={"platform"}
      >

        { view &&
          <Routes>
            <Route path="/" element={<MenuManagerPage />} />
            <Route path="/console" element={<MenuManagerPage />} />
          </Routes>
        }

        {!view &&

          <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", backgroundColor: "#101014"}}>
            <Spinner />
          </div>
        
        }

      </RootPage>
    </ThemeProvider>
  )
  

}

export default RoutePageAdminRoutes;