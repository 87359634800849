import React from "react";
import "./SlideshowComponentStyles.css"
import { getConfig } from "../../../config/config";
import { useRestaurant } from "../../../hooks/RestaurantContext";

type Props = {
    height?: string,
}

const SlideshowComponent = (props: Props) => {

    const config = getConfig();
    const { restaurantId } = useRestaurant();
    // highly pissed rn. wasted time. could have been much more productive
    if (restaurantId){
        if (config.restaurants[restaurantId].featuredImageUrl || config.defaultFeaturedImageUrl){
            return (
                <div className="slideshow-container" style={{display: "flex", width: "100%", height: props.height || "35%", backgroundColor: "#0a1239", backgroundImage: `url(${config.restaurants[restaurantId].featuredImageUrl || config.defaultFeaturedImageUrl})`, backgroundSize: "cover", backgroundPosition: "center"}} />
            )
        }
    }
    
    return <></>
    
}

export default SlideshowComponent;