import { useEffect, useState } from 'react';
import '../styles/Topbar.css'
import { FaBars, FaSun } from "react-icons/fa";
import Logo from './Logo';
import { useTheme } from '../hooks/ThemeContext';
import React from 'react';
import LogoPlatform from './LogoPlatform';


type Props = {
    children?: React.ReactNode;
    setActiveUserId: any,
    setSidebarOpen: any,
    showSidebarToggle: boolean,
    borderBottom?: string,
    height?: string,
    mobileBottomBar?: boolean,
    logo?: string
}

const Topbar = (props: Props) => {

    useEffect(()=> {
        console.log("ibearoifrwj")
        console.log(props.logo)
    }, [props.logo])

    const { theme } = useTheme();

    const onHamburgerClick = () => {
        props.setSidebarOpen((currentStatus: boolean) => !currentStatus);
    }


    return (
        <div className="top-bar" style={{backgroundColor: theme.topbarBackgroundColor, borderBottom: `1px solid ${theme.topbarBorderBottomColor}` || "1px solid rgb(218,218,218)", height: props.height || "auto", color: theme.topbarIconColor || "black", userSelect: "none"}}>
            <div className="hamburger-logo-container" style={{width: "50%"}}>
                {props.showSidebarToggle && <div className={`topbar-icon ${props.mobileBottomBar ? "hide-mobile" : ""}`} style={{color: "white"}} onClick={onHamburgerClick}><FaBars /></div>}
                {!props.showSidebarToggle && <span style={{marginLeft: "5px", height: "1px", float: "left"}}></span>}
                <div className="logo-container" onClick={() => {window.location.replace(process.env.REACT_APP_HOME_STORE_URL || "/")}}>
                    {props.logo !== "platform" && <Logo />}
                    {props.logo === "platform" && <LogoPlatform />}
                </div>
            </div>

            <div className="options-container">
                {props.children}
            </div>
            
        </div>

    )
}

export default Topbar;