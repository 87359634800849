import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from "../../hooks/ThemeContext";
import TimeService from "../../service/TimeService";
import ToastService from "../../service/ToastService";


type Props = {
    productId: string,
    productName: string,
    productPrice: number,
    currencySymbol: string,
    menus: string[],
    activeMenu: string,
    additionalOptions?: any[],
    addToCart: any,
    setModalOpen: any,
    menuSettings: any[],
    openingHours: any
}


const ProductCardAdditionalOptions = (props: Props) => {

    const [totalPrice, setTotalPrice] = React.useState(props.productPrice);
    const [selectedOptions, setSelectedOptions] = React.useState<any[]>([]);
    const [quantity, setQuantity] = React.useState(1);
    const [additionalRequests, setAdditionalRequests] = React.useState("");
    const [categories, setCategories] = React.useState<string[]>([]);

    const { theme } = useTheme();

    const handleSubmission = () => {

        TimeService.checkStoreIsOpen(props.openingHours).then((response) => {
            if (response !== true) {
                console.log("STORE OPEN RESPONSE: ", response)
                props.setModalOpen(false);
                ToastService.showToast(`Store is currently closed!`, {
                    style: {
                        backgroundColor: "darkred", // Set the toast background color to green
                        color: "white",
                        minHeight: "80px",
                        borderRadius: "15px",
                    },
                })
            }
            else {
                TimeService.checkAnyMenuActive(props.menuSettings, props.menus).then((response) => {
                    if (response == true) {
                        if (props.productId && additionalRequests.length <= 200) {
                            props.addToCart({"id": props.productId, "additionalOptionIds": selectedOptions, "additionalRequests": additionalRequests, quantity: quantity, menus: props.menus});
                            props.setModalOpen(false);
                            ToastService.showToast("Item added to cart!", {
                                style: {
                                    backgroundColor: "darkgreen", // Set the toast background color to green
                                    color: "white",
                                    minHeight: "80px",
                                    borderRadius: "15px",
                                },
                            });
                        }
                    }
                    else {
                        props.setModalOpen(false);
                        ToastService.showToast(`Items from ${props.activeMenu} are not available at this time!`, {
                            style: {
                                backgroundColor: "darkred", // Set the toast background color to green
                                color: "white",
                                minHeight: "80px",
                                borderRadius: "15px",
                            },
                        })
                    }
                })
            }
        })

    }

    React.useEffect(() => {
        let c : string[] = [];

        if (props.additionalOptions) {
            props.additionalOptions.map((option) => {
                if (option.heading) {
                    if (!c.includes(option.heading)) {
                        c.push(option.heading)
                    }
                }
                else {
                    c.push("Frequently Bought Together:")
                }
            })
            // console.log(c);
            c = Array.from(new Set(c));
            setCategories(c);
        }
    }, [props.additionalOptions])

    return (
        <>
            <h2>{props.productName}</h2>
                {props.additionalOptions && props.additionalOptions.length > 0 && <div>
                    {categories.map((category) => {
                        return (

                            <>
                            <p style={{marginBottom: "0"}}>{category}</p>
                            
                            {props.additionalOptions?.filter((o) => o.heading === category || (category === "Frequently Bought Together:" && !(o.heading))).map((option, index) => {
                                return (
                                    <div key={index} style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                                        <input type="checkbox" onChange={(e) => {
                                            if (e.target.checked) {
                                                setTotalPrice(currentPrice => currentPrice + option.productPrice);
                                                setSelectedOptions(prevItems => [...prevItems, option.productId])
                                            }
                                            else {
                                                setTotalPrice(currentPrice => currentPrice - option.productPrice);
                                                setSelectedOptions(prevItems => prevItems.filter(item => item !== option.productId))
                                            }
                                        }
                                        }/>
                                        <span style={{marginLeft: "5px"}}>{option.productName}</span>
                                        <span style={{fontWeight: "bold", marginLeft: "20px"}}> {props.currencySymbol + (Math.round(option.productPrice * 100) / 100).toFixed(2)}</span>
                                    </div>
                                )
                            })}

                            </>
                    
                        )
                    })}

                </div>}

                <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: "30px"}}>
                    <label>Additional Requests</label>
                    <textarea maxLength={200} style={{marginTop: "10px", height: "100px", padding: "10px", boxSizing: "border-box"}} onChange={(e:any)=>setAdditionalRequests(e.target.value)}/>
                    <span style={{color: "red", fontSize: 'small'}}>Your request will be submitted but is not guaranteed</span>
                </div>

                <div style={{display: "flex", flexDirection: "row", marginTop: "30px", width: "10%", height: "20px"}}>
                    <label style={{marginRight: "15px"}} >Quantity</label>
                    <select id="quantity" onChange={(e) => setQuantity(parseInt(e.target.value))}>
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i: number) => {
                                return (
                                    <option key={i} value={i} >{i}</option>
                                )
                            })
                        }
                    </select>
                </div>

                <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
                    <button className="button primary" onClick={handleSubmission} style={{borderRadius: "5px", border: "none", padding: "10px", backgroundColor: theme.cartButtonColor, color: theme.cartButtonTextColor, cursor: "pointer"}} >Add to Cart ({props.currencySymbol + totalPrice.toFixed(2)})</button>
                </div>
        </>
    )
}

export default ProductCardAdditionalOptions;